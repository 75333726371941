/**
 * Extract hostname from URL
 *
 * @param {string} url
 */
export function getHostName(url) {
  // scheme : // [username [: password] @] hostame [: port] [/ [path] [? query] [# fragment]]
  const e = new RegExp('^(?:(?:https?|ftp):)/*(?:[^@?]+@)?([^:/#]+)');
  const matches = e.exec(url);

  return matches ? matches[1] : url;
}
